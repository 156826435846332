//chakra ui navbar with title and dark mode toggle

import { Box, Flex, useColorMode, Divider, Heading, Spacer, Button } from "@chakra-ui/react";
import { Navigate } from "react-router";
import { useAuth } from "../util/auth.context";

export function Header() {
	const { logout } = useAuth();
	const { colorMode, toggleColorMode } = useColorMode();
	const handleLogOut = () => {
		logout();
		localStorage.removeItem("user");
		localStorage.removeItem("token");
	}
	return (
		<Box>
			<Flex p={4}>
				<Heading size="lg">
					Lex3D Data Annotation Tool
				</Heading>
				<Spacer />
				<Button onClick={toggleColorMode}>
					{colorMode === "light" ? "Dark" : "Light"}
				</Button>
				<Button colorScheme="primary" onClick={handleLogOut}>
					Log out
				</Button>
			</Flex>
			<Divider />
		</Box>
	)
}