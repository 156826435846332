import { Box, Heading, Text, Button, Flex, Spacer, Image, Link, useColorMode, CSSReset } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export function Home() {
	const navigate = useNavigate();
    return (
        <Box className="container--home">
			<Button colorScheme="primary" onClick={() => navigate("/gallery")}>
				Gallery
			</Button>
        </Box>
    )
}