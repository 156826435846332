import styled from "styled-components";
import { Icon } from "@chakra-ui/react";
import { BsTagsFill } from "react-icons/bs";
import { Avatar, SlideFade, Text } from "@chakra-ui/react/dist";
import CardSkeleton from "./CardSkeleton";
import { useEffect, useState } from "react";

const CardContainer = styled.div`
	width: 300px;
	height: 300px;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	cursor: pointer;
`;

const CardImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
`;

const CardHeader = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	padding: 0.5rem;
`;

const Tags = styled.div`
	flex-flow: row nowrap;
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	padding: 1px;
	padding-bottom: 0.5rem;
`;

const StyledIcon = styled(Icon)`
	background-color: rgba(0, 0, 0, 0.5);
	padding: 0.35rem;
    width: 30px;
    height: 30px;
	border-radius: 9999px;
	margin-right: 0.5rem;
`;

const Tag = styled.div`
	padding: 0.25rem 0.5rem;
	border-radius: 9999px;
	font-size: 0.75rem;
	font-weight: 500;
	color: #fff;
	background-color: rgba(0, 0, 0, 0.5);
	margin-right: 0.5rem;
	outline: 1px solid #444444;
`;

const CardFooter = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 1rem;
	color: #fff;
	background-image: linear-gradient(
		to top,
		rgba(0, 0, 0, 0.8),
		rgba(0, 0, 0, 0)
	);
	font-size: 1rem;
	font-weight: 500;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	align-items: flex-start;
`;

const ModelName = styled.h1`
	color: #fff;
	font-size: 2rem;
	font-weight: 500;
	line-height: 1.2;
	margin-bottom: 0.5rem;
`;

const User = styled.div`
	display: flex;
	flex-flow: row nowrap;
`;

const Card = ({model}) => {
	const [loading, setLoading] = useState(true);

	const images = [
		"https://images.unsplash.com/photo-1689099687200-a092b8a916fb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3560&q=80",
		"https://images.unsplash.com/photo-1559703248-dcaaec9fab78?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8M0QlMjBvYmplY3R8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
		"https://images.unsplash.com/photo-1620987278429-ab178d6eb547?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8M0QlMjBvYmplY3R8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
		"https://images.unsplash.com/photo-1557180295-76eee20ae8aa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fDNEJTIwb2JqZWN0fGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
		"https://images.unsplash.com/photo-1559181567-c3190ca9959b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8b2JqZWN0fGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
		"https://images.unsplash.com/photo-1533910534207-90f31029a78e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fG9iamVjdHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
		
	]
	const random = Math.floor(Math.random() * images.length);
	const tags = [
		'Low Poly',
		'Furniture',
		'Couch',
		'Brown'
	]
	const imageSrc = images[random];

	useEffect(() => {
	  const img = new Image();
	  img.src = imageSrc;
	  img.onload = () => {
		setLoading(false);
		console.log("Image loaded");
	  };
	  img.onerror = () => {
		console.log("Error loading image");
	  };
	}, [imageSrc]);
	return (
		<SlideFade in={true} offsetY="20px" transition={{ enter: { duration: 0.5, delay: Math.random() * 1.5 } }}>
		{loading ? <CardSkeleton /> :
		<CardContainer>
			<CardImage alt="card image" src={imageSrc} />
			<CardHeader>
				<StyledIcon as={BsTagsFill} mr={1} color="white" />
				<Tags>
				{tags.map((tag, i) => (
					<Tag key={i} >
						{tag}
					</Tag>
				))}
				</Tags>
			</CardHeader>
			<CardFooter>
				<ModelName>Eu occaecat commodo</ModelName>
				<User>
					<Avatar name="Dan Abrahmov" src="https://bit.ly/dan-abramov" mr={2} size='sm' />
					<Text>
						by Dan Abrahmov
					</Text>
				</User>
			</CardFooter>
		</CardContainer>}
		</SlideFade>
	);
};

export default Card;