//private route
import { Navigate } from "react-router-dom";
import { useAuth } from "../util/auth.context";

export function PrivateRoute({ Component, ...rest }) {
	const auth = useAuth();
	console.log(auth);
	return (
		auth.currentUser ?
		<Component {...rest} /> :
		<Navigate to="/login" replace={true} />
	);
}