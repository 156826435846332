import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PrivateRoute } from "../components/PrivateRoute";
import { useEffect } from "react";
import { ChakraProvider, Box } from '@chakra-ui/react';
import theme from '../css/chakraTheme'
import { Header } from "../components/Header";
import { AuthProvider } from "../util/auth.context";

import { Login } from "./Login";
import { Home } from "./Home";
import Gallery from './Gallery'

export function App() {
	return (
		<ChakraProvider theme={theme}>
			<AuthProvider>
				<Header />
				<Box p={4}>
					<BrowserRouter>
						<Routes>
							<Route exact path="/" element={<PrivateRoute Component={Home} />} />
							<Route exact path="/gallery" element={<PrivateRoute Component={Gallery} />} />
							<Route exact path='/login' element={<Login />} />
						</Routes>
					</BrowserRouter>
				</Box>
			</AuthProvider>
		</ChakraProvider>
	)
  }