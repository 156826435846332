// Creat a login page using chakra ui components and firebase authentication
// Path: src/pages/Login.jsx
import { useState } from "react";
import { Box, Heading, Text, Button, Flex, Spacer, Image, Link, useColorMode, CSSReset, FormControl, FormLabel, Input, FormHelperText } from "@chakra-ui/react";
import { auth } from "../util/firebase";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../util/auth.context";

export function Login() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const { login } = useAuth();
	const navigate = useNavigate();
	const handleSignIn = () => {
		login(email, password)
		.then((userCredential) => {
			// Signed in
			console.log(userCredential);
			const user = userCredential.user;
			localStorage.setItem("user", JSON.stringify(user));
			localStorage.setItem("token", user.za);
			navigate("/");

		})
		.catch((error) => {
			const errorCode = error.code;
			const errorMessage = error.message;
			setError(errorMessage);
		});
	}
	return (
		<Box className="container--login">
			<Flex className="container--login__header">
				<Heading size="lg" className="container--login__header__heading">
					Login
				</Heading>
				<Spacer />
			</Flex>
			<Flex className="container--login__content">
				<Box className="container--login__content__text">
					<form>
					<FormControl id="email">
						<FormLabel>Email address</FormLabel>
						<Input type="email" onChange={(e) => setEmail(e.target.value)} autoComplete="email" />
					</FormControl>
					<FormControl id="password">
						<FormLabel>Password</FormLabel>
						<Input type="password" onChange={(e) => setPassword(e.target.value)} autoComplete="current-password" />
					</FormControl>
					<Button onClick={handleSignIn}>
						Login
					</Button>
					<Text fontSize="xl">
						{error}
					</Text>
					</form>
				</Box>
				<Spacer />
			</Flex>
		</Box>
	)
}