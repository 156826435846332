import Card from "./Card";
import { SimpleGrid
 } from "@chakra-ui/react/dist";
import CardSkeleton from "./CardSkeleton";

const Gallery = () => {
	return (
		<SimpleGrid spacing="30px" minChildWidth='300px' justifyItems='center'>
			{[...Array(30)].map((_, i) => (
				<Card key={i} />
			))}
		</SimpleGrid>
	);
};

export default Gallery;