import { SkeletonCircle, SkeletonText, Skeleton, Box, Flex } from "@chakra-ui/react/dist";
import styled from "styled-components";
const SkeletonWrapper = styled(Box)`
	width: 300px;
	height: 300px;
	border-radius: 10px;
	position: relative;
	padding: 0.5rem;
`;

const CardFooter = styled.div`
	padding: 0.5rem;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
`;

const CardSkeleton = () => {
	return (
		<SkeletonWrapper maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden" boxShadow="lg">
			<Flex alignItems='center'>
				<SkeletonCircle size="8" />
				<Skeleton height="15px" width="60px" ml="2" />
				<Skeleton height="15px" width="40px" ml="2" />
				<Skeleton height="15px" width="70px" ml="2" />
			</Flex>
			<CardFooter>
				<SkeletonText mb="4" noOfLines={2} spacing="4" />
				<Flex alignItems="center">
					<SkeletonCircle size="9" mr="2" />
					<Skeleton height="10px" width="100px" />
				</Flex>
			</CardFooter>
		</SkeletonWrapper>
	);
};

export default CardSkeleton;