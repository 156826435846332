import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  styles: {
    global: (props) => ({
      body: {
        color: props.colorMode === "light" ? "#130e01" : "#fef9ec",
        bg: props.colorMode === "light" ? "#fffaeb" : "#140f00",
      },
    }),
  },
  colors: {
    primary: {
      500: "#ff8400",
    },
    // For other colors, you can define them in components
  },
  components: {
    Button: {
      // style the base styles
      baseStyle: (props) => ({
        color: props.colorMode === "dark" ? "#130e01" : "#fef9ec",
        backgroundColor: props.colorMode === "dark" ? "#fff5d6" : "#291f00",
      }),
      variants: {
        accent: (props) => ({
          backgroundColor: props.colorMode === "light" ? "#cf4307" : "#f86c30",
        }),
      },
    },
    // Input: {
    //   variants: {
    //     filled: (props) => ({
    //       field: {
    //         backgroundColor: props.colorMode === "light" ? "#fffaeb" : "#140f00",
    //       },
    //     }),
    //   },
    // },
    Link: {
      baseStyle: (props) => ({
        color: props.colorMode === "light" ? "#130e01" : "#fef9ec",
      }),
    },
  },
});

export default theme;
